import React from 'react';

import { socket } from '@app/api';

import { useMainContext } from '../main';
import { ACTIONS } from '../main/actions';


export interface ConnectionContextInterface {
};

export const useConnectionHook = (
): ConnectionContextInterface => {
  const {
    generalDataSet,
    personalDataSet,
    callRun,
    offerSet,
    iceCandidateSet,
    operatorCallStop,
    operatorCallRejected,
    clientDisconnect,
  } = useMainContext();

  React.useEffect(() => {
    socket.on(ACTIONS.ICE_CANDIDATE, iceCandidateSet);
    return () => {socket.off(ACTIONS.ICE_CANDIDATE, iceCandidateSet);};
  }, [ iceCandidateSet ]);

  React.useEffect(() => {
    socket.on(ACTIONS.OFFER_TO_CALLER_SEND, offerSet);
    return () => {socket.off(ACTIONS.OFFER_TO_CALLER_SEND, offerSet);};
  }, [ offerSet ]);

  React.useEffect(() => {
    socket.on(ACTIONS.OPERATOR_CALL_START, callRun);
    return () => {socket.off(ACTIONS.OPERATOR_CALL_START, callRun)};
  }, [ callRun ]);

  React.useEffect(() => {
    socket.on(ACTIONS.OPERATOR_CALL_STOP, operatorCallStop);
    return () => {socket.off(ACTIONS.OPERATOR_CALL_STOP, operatorCallStop)};
  }, [ operatorCallStop ]);

  React.useEffect(() => {
    socket.on(ACTIONS.OPERATOR_CALL_REJECT, operatorCallRejected);
    return () => {socket.off(ACTIONS.OPERATOR_CALL_REJECT, operatorCallRejected)};
  }, [ operatorCallRejected ]);

  React.useEffect(() => {
    socket.on(ACTIONS.CLIENT_DISCONNECT, clientDisconnect);
    return () => {socket.off(ACTIONS.CLIENT_DISCONNECT, clientDisconnect)};
  }, [clientDisconnect]);

  React.useEffect(() => {
    socket.on(ACTIONS.DATA_GENERAL_SEND, generalDataSet);
    return () => {socket.off(ACTIONS.DATA_GENERAL_SEND, generalDataSet)};
  }, [ generalDataSet ]);

  React.useEffect(() => {
    socket.on(ACTIONS.DATA_TO_CALLER_SEND, personalDataSet);
    return () => { socket.off(ACTIONS.DATA_TO_CALLER_SEND, personalDataSet);};
  }, [ personalDataSet ]);
  
  return React.useMemo(() => ({
  }), [
  ]);
};
