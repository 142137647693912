import React from 'react';

import {
  ConnectionContextInterface,
  useConnectionHook,
} from './connection.hook';

const ConnectionContext = React.createContext<ConnectionContextInterface | undefined>(undefined);

export const useConnectionContext = () => {
  const context = React.useContext(ConnectionContext);

  if (context === undefined) {
    throw new Error('useConnectionContext must be used within ConnectionProvider');
  }

  return context;
}

export const ConnectionProvider = ({
  children,
}: React.HTMLAttributes<HTMLDivElement>) => {
  const api = useConnectionHook();

  return (
    <ConnectionContext.Provider value={api}>
      {children}
    </ConnectionContext.Provider>
  );
};
