import { RouterOutletPart, ThemeProviderPart, NotifyPart, LocalizationProviderPart } from './parts';


export const App = () => {
  return (
    <LocalizationProviderPart>
      <ThemeProviderPart>
        <RouterOutletPart />
        <NotifyPart />
        {/* <LoaderPart /> */}
      </ThemeProviderPart>
    </LocalizationProviderPart>
  );
}
