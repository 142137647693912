import React from 'react';

import { CallFeature } from '@app/components'


export const HomePage = () => {
  return (
    <CallFeature />
  );
};
