import { MainComponent } from './components';
import { ConnectionProvider, MainProvider } from './context';

export const CallFeature = () => {
  return (
    <MainProvider>
      <ConnectionProvider>
        <MainComponent />
      </ConnectionProvider>
    </MainProvider>
  );
};
