import { create } from 'zustand';

import { BreadcrumbType } from '@app/types';


export interface BreadcrumbsStoreInterface {
  breadcrumbs: BreadcrumbType[];
  // breadcrumbsHomeSet: () => void;
};

export const useBreadcrumbsStore = create<BreadcrumbsStoreInterface>((set) => ({
  breadcrumbs: [],
  // breadcrumbsHomeSet: () => set(() => ({
  //   breadcrumbs: [{ title: 'Главная', link: '/' }],
  // })),
}));
