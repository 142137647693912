import React from 'react';

import {
  QueueAddContextInterface,
  useQueueAddHook,
} from './queue-add.hook';

const QueueAddContext = React.createContext<QueueAddContextInterface | undefined>(undefined);

export const useQueueAddContext = () => {
  const context = React.useContext(QueueAddContext);

  if (context === undefined) {
    throw new Error('useQueueAddContext must be used within QueueAddProvider');
  }

  return context;
}

export const QueueAddProvider = ({
  children,
}: React.HTMLAttributes<HTMLDivElement>) => {
  const api = useQueueAddHook();

  return (
    <QueueAddContext.Provider value={api}>
      {children}
    </QueueAddContext.Provider>
  );
};
