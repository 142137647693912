import React from 'react';

import { BackendErrorsType, ChangeFieldEventType } from '@app/types';
import { translateErrorHelper } from '@app/helpers';

import { useMainContext } from '../main';
import { useAppStore } from '@app/stores';


const initialFormValue = {
  lastName: '',
  firstName: '',
  secondName: '',
  phone: '',
  snils: '',
};

export interface QueueAddContextInterface {
  formValue: typeof initialFormValue;
  formDirty: boolean;
  formIsSubmitting: boolean,
  // errorConfirm: string | null;
  changeFormValue: (e: ChangeFieldEventType) => void;
  onSubmit: (e: React.SyntheticEvent) => void;
  errorSet: (fieldName: string) => string | null;
};

export const useQueueAddHook = (
): QueueAddContextInterface => {
  const {
    callerStadnToQueue,
  } = useMainContext();
  const { notifyCall } = useAppStore();
  const [ formValue, formValueSet ] = React.useState(initialFormValue);
  const [ formDirty, formDirtySet ] = React.useState(false);
  const [ formSended, formSendedSet ] = React.useState(false);
  const [ formIsSubmitting, formIsSubmittingSet ] = React.useState(false);
  const [ errors, errorsSet ] = React.useState<BackendErrorsType | null>(null);
  
  const changeFormValue = React.useCallback((e: ChangeFieldEventType): void => {
    formDirtySet(true);
    formValueSet((state) => {
      return {
        ...state,
        [e.target.name]: e.target.value,
      };
    });
  }, []);

  const validateForm = React.useCallback((): boolean => {
    errorsSet(null);
    let hasErrors = false;

    if (formValue.firstName.length === 0) {
      hasErrors = true;
      errorsSet((state) => {
        if (state === null) return {
          firstName: ['should not be empty'],
        };
        return {
          ...state,
          firstName: ['should not be empty'],
        }
      })
    }

    if (formValue.phone.length === 0) {
      hasErrors = true;
      errorsSet((state) => {
        if (state === null) return {
          phone: ['should not be empty'],
        };
        return {
          ...state,
          phone: ['should not be empty'],
        }
      })
    }

    if (formValue.phone.length !== 0 && formValue.phone.length < 12) {
      hasErrors = true;
      errorsSet((state) => {
        if (state === null) return {
          phone: ['not valid phone'],
        };
        return {
          ...state,
          phone: ['not valid phone'],
        }
      })
    }

    if (formValue.snils.length === 0) {
      hasErrors = true;
      errorsSet((state) => {
        if (state === null) return {
          snils: ['should not be empty'],
        };
        return {
          ...state,
          snils: ['should not be empty'],
        }
      })
    }

    if (formValue.snils.length !== 0 && formValue.snils.length < 14) {
      hasErrors = true;
      errorsSet((state) => {
        if (state === null) return {
          snils: ['not valid snils'],
        };
        return {
          ...state,
          snils: ['not valid snils'],
        }
      })
    }

    if (formValue.lastName.length === 0) {
      hasErrors = true;
      errorsSet((state) => {
        if (state === null) return {
          lastName: ['should not be empty']
        };
        return {
          ...state,
          lastName: ['should not be empty'],
        }
      })
    }

    if (formValue.secondName.length === 0) {
      hasErrors = true;
      errorsSet((state) => {
        if (state === null) return {
          secondName: ['should not be empty']
        };
        return {
          ...state,
          secondName: ['should not be empty'],
        }
      })
    }

    return hasErrors;
  }, [
    formValue.firstName.length,
    formValue.lastName.length,
    formValue.secondName.length,
    formValue.phone.length,
    formValue.snils.length,
  ]);

  const onSubmit = React.useCallback(async (e: React.SyntheticEvent) => {
    e.preventDefault();

    if (formValue === null) return;
    formSendedSet(true)
    formIsSubmittingSet(true);
    const hasError = validateForm();

    if (hasError) {
      formIsSubmittingSet(false);
      notifyCall({
        type: 'error',
        message: 'Проверьте корректность введенных данных',
      })
      return;      
    }

    callerStadnToQueue(formValue);
  }, [
    formValue,
    callerStadnToQueue,
    notifyCall,
    validateForm,
  ]);

  const errorSet = React.useCallback((
    fieldName: string,
  ): string | null => {
    return (formSended && errors && errors[fieldName])
      ? translateErrorHelper(errors[fieldName][0])
      : null;
  }, [
    formSended,
    errors,
  ]);

  React.useEffect(() => {

    formValueSet(initialFormValue);
    formDirtySet(false);
    formSendedSet(false);
    formIsSubmittingSet(false);
  }, [
  ]);

  return React.useMemo(() => ({
    formValue,
    formDirty,
    formIsSubmitting,
    // errorConfirm,
    changeFormValue,
    onSubmit,
    errorSet,
  }), [
    formValue,
    formDirty,
    formIsSubmitting,
    // errorConfirm,
    changeFormValue,
    onSubmit,
    errorSet,
  ]);
};
