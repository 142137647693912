import {
  ButtonBrick,
  ButtonIconBrick,
  ChipBrick,
  DialogBrick,
} from '@app/agui/bricks';

import {
  FieldSelectBlock,
  FormCenteredBlock,
} from '@app/agui/blocks';

import {
  ButtonConfirmComposition,
  CardDataComposition,
} from '@app/agui/compositions';

import {
  CallEndIcon,
  CameraIcon,
  CameraOffIcon,
  MicrophoneIcon,
  MicrophoneOffIcon,
  SettingsIcon,
} from '@app/agui/icons';

import { useMainContext } from '../../context';

import styles from './controls.module.scss';


export const ControlsComponent = () => {
  const {
    cameraMuted,
    microphoneMuted,
    microphoneList,
    cameraList,
    cameraDeviceId,
    microphoneDeviceId,
    modalSettingsOpened,
    modalSettingsOpen,
    modalSettingsClose,
    cameraToggle,
    microphoneToggle,
    callerCallStop,
    cameraSet,
    microphoneSet,
  } = useMainContext();

  return (
    <div className={styles['controls']}>
      <div className={styles['controls__info']}>
        {microphoneMuted && <ChipBrick color="warning" label="Вас не слышно, включите микрофон"/>}
        {cameraMuted && <ChipBrick color="warning" label="Вас не видно, включите камеру"/>}
      </div>
      <div className={styles['controls__block']}>
        <ButtonIconBrick
          size="large"
          onClick={microphoneToggle}
        >
          {microphoneMuted ? <MicrophoneOffIcon /> : <MicrophoneIcon />}
        </ButtonIconBrick>
        <ButtonIconBrick
          size="large"
          onClick={cameraToggle}
        >
          {cameraMuted ? <CameraOffIcon /> : <CameraIcon />}
        </ButtonIconBrick>
        <ButtonConfirmComposition
          type="icon"
          onClick={() => callerCallStop()}
          message="Завершить звонок?"
          buttonText="Завершить звонок"
          icon={<CallEndIcon />}
          size="large"
          color="error"
        />
        <ButtonIconBrick
          size="large"
          onClick={modalSettingsOpen}
        >
          <SettingsIcon />
        </ButtonIconBrick>
      </div>
      <DialogBrick
        opened={modalSettingsOpened}
        onClose={modalSettingsClose}
        size="fullscreen"
      >
        <div className="container" style={{ paddingTop: '45px' }}>
          <CardDataComposition
            title="Настройки"
          >
            <FormCenteredBlock
              onSubmit={()=>{}}
              buttons={
                <ButtonBrick
                  onClick={modalSettingsClose}
                  fullWidth
                  size="large"
                >
                  Закрыть
                </ButtonBrick>}
            >
              <FieldSelectBlock
                name="camera"
                value={cameraDeviceId}
                onChange={(e) => cameraSet(e.target.value as string)}
                items={cameraList.map((camera) => ({ value: camera.deviceId, label: camera.label }))}
                label="Камера"
              />
              <FieldSelectBlock
                name="microphone"
                value={microphoneDeviceId}
                onChange={(e) => microphoneSet(e.target.value as string)}
                items={microphoneList.map((microphone) => ({ value: microphone.deviceId, label: microphone.label }))}
                label="Микрофон"
              />
            </FormCenteredBlock>
          </CardDataComposition>
        </div>
      </DialogBrick>
    </div>
  );
};
