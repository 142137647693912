import React from 'react';

import { socket } from '@app/api';
import { useAppStore } from '@app/stores';

import { ACTIONS } from './actions';


export interface MainContextInterface {
  operatorListLen: number;
  callerListLen: number;
  personalQueueIdx: number;
  callStatus: CallStatusType;
  generalDataSet: (event: any) => void;
  personalDataSet: (event: any) => void;
  provideMediaRef: (id: string, node: HTMLVideoElement | null) => void;
  callRun: (event: { operator: OperatorType }) => void;
  offerSet: (event: { operator: OperatorType; offer: RTCSessionDescriptionInit; }) => void;
  iceCandidateSet: (event: { iceCandidate: RTCIceCandidate, clientId: string }) => void;
  operatorCallStop: () => void;
  operatorCallRejected: () => void;
  callerCallStop: () => void;
  clientDisconnect: (event: { clientId: string }) => void;

  callerName: string;
  operatorCurrent: OperatorType | null;
  cameraMuted: boolean;
  microphoneMuted: boolean;
  microphoneList: MediaDeviceInfo[];
  cameraList: MediaDeviceInfo[];
  cameraDeviceId: string | null;
  microphoneDeviceId: string | null;
  modalSettingsOpened: boolean;
  callerStadnToQueue: (payload: callerStadnToQueuePayload) => void;
  goToHome: () => void;
  cameraToggle: () => void;
  microphoneToggle: () => void;
  cameraSet: (deviceId: string) => void;
  microphoneSet: (deviceId: string) => void;
  modalSettingsOpen: () => void;
  modalSettingsClose: () => void;
};

type CallStatusType = 'idle' | 'pending' | 'call' | 'done';

interface callerStadnToQueuePayload {
  firstName: string;
  lastName: string;
  secondName: string;
};

type OperatorType = {
  userId: number,
  firstName: string;
  lastName: string;
  secondName: string | null;
  clientId: string;
};

export const useMainHook = (
): MainContextInterface => {
  const { notifyCall } = useAppStore();

  const [ operatorListLen, operatorListLenSet ] = React.useState(0);
  const [ callerListLen, callerListLenSet ] = React.useState(0);
  const [ personalQueueIdx, personalQueueIdxSet ] = React.useState(0);
  const [ callStatus, callStatusSet ] = React.useState<CallStatusType>('idle');
  const [ callerName, callerNameSet ] = React.useState('');
  const [ operatorCurrent, operatorCurrentSet ] = React.useState<OperatorType | null>(null);
  const [ cameraMuted, cameraMutedSet ] = React.useState(false);
  const [ microphoneMuted, microphoneMutedSet ] = React.useState(false);
  const [ microphoneList, microphoneListSet ] = React.useState<MediaDeviceInfo[]>([]);
  const [ cameraList, cameraListSet ] = React.useState<MediaDeviceInfo[]>([]);
  const [ cameraDeviceId, cameraDeviceIdSet ] = React.useState<string | null>(null);
  const [ microphoneDeviceId, microphoneDeviceIdSet ] = React.useState<string | null>(null);
  const [ modalSettingsOpened, modalSettingsOpenedSet ] = React.useState(false);

  const peerConnections = React.useRef<{ [key: string]: RTCPeerConnection }>({});
  const localMediaStream = React.useRef<MediaStream | null>(null);
  const peerMediaElements = React.useRef<{ [key: string]: HTMLVideoElement | null }>({});

  const modalSettingsOpen = React.useCallback(() => {
    modalSettingsOpenedSet(true);
  }, []);

  const modalSettingsClose = React.useCallback(() => {
    modalSettingsOpenedSet(false);
  }, []);

  const generalDataSet = React.useCallback((event: any) => {
    callerListLenSet(event.callerListLen);
    operatorListLenSet(event.operatorListLen);
  }, []);

  const personalDataSet = React.useCallback((event: any) => {
    personalQueueIdxSet(event.personalQueueIdx);
    operatorListLenSet(event.operatorListLen);
  }, []);

  const callerStadnToQueue = React.useCallback(async (payload: callerStadnToQueuePayload) => {
    // отправка данных об звонящем
    socket.emit(ACTIONS.CALLER_CONNECT, payload);
    
    // установка имени и статуса
    callerNameSet(payload.lastName + ' ' + payload.firstName + ' ' + payload.secondName);
    callStatusSet('pending');
    
    // включение стрима
    localMediaStream.current = await navigator.mediaDevices.getUserMedia({
      audio: true,
      video: {
        width: 1280,
        height: 720,
      },
    });

    // устанавливаем текущие девайсы
    localMediaStream.current.getTracks().forEach((track) => {
      if (track.kind === 'audio') {
        microphoneDeviceIdSet(track.getSettings().deviceId || null);
      }

      if (track.kind === 'video') {
        cameraDeviceIdSet(track.getSettings().deviceId || null);
      }
    });

    const localVideoElement = peerMediaElements.current.local;
    if (localVideoElement) {
      localVideoElement.volume = 0;
      localVideoElement.srcObject = localMediaStream.current;
    }

    // получение данных об девайсах
    const devices = await navigator.mediaDevices.enumerateDevices();
    const microphones = devices.filter((device) => device.kind === 'audioinput');
    const cameras = devices.filter((device) => device.kind === 'videoinput');
    microphoneListSet(microphones);
    cameraListSet(cameras);
  }, []);

  const provideMediaRef = React.useCallback((id: string, node: HTMLVideoElement | null) => {
    peerMediaElements.current[id] = node;
  }, []);

  const callRun = React.useCallback(async ( event: { operator: OperatorType }) => {
    const { operator } = event;
    const { clientId } = operator;
    
    if (clientId in peerConnections.current) {
      return console.warn('callRun - peer already exist. ID - ', clientId);
    }

    operatorCurrentSet(operator);

    peerConnections.current[clientId] = new RTCPeerConnection({
      // iceServers: freeice(),
      iceServers: [{
        urls: ['stun:80.90.189.135:3478']
      }],
    });

    peerConnections.current[clientId].onicecandidate = (ev) => {
      if (ev.candidate) {
        socket.emit(ACTIONS.RELAY_ICE, { iceCandidate: ev.candidate, to: clientId });
      }
    }

    let tracksNumber = 0;
    peerConnections.current[clientId].ontrack = ({streams: [remoteStream]}) => {
      tracksNumber ++;

      if (tracksNumber === 2) {
        tracksNumber = 0;
        if (peerMediaElements.current['remote']) {
          peerMediaElements.current['remote'].srcObject = remoteStream;
        } else {
          // FIX LONG RENDER IN CASE OF MANY CLIENTS
          let settled = false;
          const interval = setInterval(() => {
            if (peerMediaElements.current['remote']) {
              peerMediaElements.current['remote'].srcObject = remoteStream;
              settled = true;
            }
  
            if (settled) {
              clearInterval(interval);
            }
          }, 1000);
        }
      }
    }

    if (localMediaStream.current !== null) {
      localMediaStream.current.getTracks().forEach((track) => {
        peerConnections.current[clientId].addTrack(track, (localMediaStream.current as MediaStream));
      });
    }

    callStatusSet('call');
  }, []);

  const offerSet = React.useCallback(async (event: { operator: OperatorType; offer: RTCSessionDescriptionInit; }) => {
    const { operator } = event;
    const { clientId } = operator;
    
    if (!peerConnections.current[clientId]) {
      return console.warn('offerSet - peerConnection not exist');
    }
    
    await peerConnections.current[clientId].setRemoteDescription(
      new RTCSessionDescription(event.offer),
    );

    const answer = await peerConnections.current[clientId].createAnswer();
    await peerConnections.current[clientId].setLocalDescription(answer);

    socket.emit(ACTIONS.CALLER_SEND_ANSWER, {
      clientId,
      answer,
    });
  }, []);

  const iceCandidateSet = React.useCallback((event: { iceCandidate: RTCIceCandidate, clientId: string }) => {
    const { iceCandidate, clientId } = event;

    if (!peerConnections.current[clientId] || !peerConnections.current[clientId].remoteDescription) {
      return console.warn('iceCandidateSet - peerConnection not exist');
    }

    peerConnections.current[clientId].addIceCandidate(
      new RTCIceCandidate(iceCandidate),
    );
  }, []);

  const cameraToggle = React.useCallback(() => {
    if (localMediaStream.current === null) return;
    const videoTrack = localMediaStream.current.getTracks().find(track => track.kind === 'video');
    if (!videoTrack) return;
 
    videoTrack.enabled = cameraMuted;
    cameraMutedSet((state) => !state);
  }, [
    cameraMuted,
  ]);

  const microphoneToggle = React.useCallback(() => {
    if (localMediaStream.current === null) return;
    const audioTrack = localMediaStream.current.getTracks().find(track => track.kind === 'audio');
    if (!audioTrack) return;
 
    audioTrack.enabled = microphoneMuted;
    microphoneMutedSet((state) => !state);
  }, [
    microphoneMuted,
  ]);

  const cameraSet = React.useCallback(async (deviceId: string) => {
    if (microphoneDeviceId === null) return;

    cameraDeviceIdSet(deviceId);

    localMediaStream.current = await navigator.mediaDevices.getUserMedia({
      audio: {
        deviceId: microphoneDeviceId,
        echoCancellation: true,
      },
      video: {
        deviceId: { exact: deviceId },
        width: 1280,
        height: 720,
      },
    });

    const localVideoElement = peerMediaElements.current.local;
    if (localVideoElement) {
      localVideoElement.volume = 0;
      localVideoElement.srcObject = localMediaStream.current;
    }
    
    if (operatorCurrent !== null && localMediaStream.current !== null) {
        const videoTrack = localMediaStream.current.getVideoTracks()[0];
        const sender = peerConnections
          .current[operatorCurrent.clientId]
          .getSenders()
          .find((s) => s.track && s.track.kind === 'video');

        if (sender === undefined || videoTrack === undefined) {
          return;
        }

        sender.replaceTrack(videoTrack);

        modalSettingsOpenedSet(false);
    }
  }, [
    microphoneDeviceId,
    operatorCurrent,
  ]);

  const microphoneSet = React.useCallback(async (deviceId: string) => {
    if (cameraDeviceId === null) return;

    microphoneDeviceIdSet(deviceId);

    localMediaStream.current = await navigator.mediaDevices.getUserMedia({
      audio: {
        deviceId,
        echoCancellation: true,
      },
      video: {
        deviceId: cameraDeviceId,
        width: 1280,
        height: 720,
      },
    });

    const localVideoElement = peerMediaElements.current.local;
    if (localVideoElement) {
      localVideoElement.volume = 0;
      localVideoElement.srcObject = localMediaStream.current;
    }
    
    if (operatorCurrent !== null && localMediaStream.current !== null) {
      const audioTrack = localMediaStream.current.getAudioTracks()[0];
      const sender = peerConnections
        .current[operatorCurrent.clientId]
        .getSenders()
        .find((s) => s.track && s.track.kind === 'audio');

      if (sender === undefined || audioTrack === undefined) {
        return;
      }

      sender.replaceTrack(audioTrack);
    }

    modalSettingsOpenedSet(false);
  }, [
    cameraDeviceId,
    operatorCurrent,
  ]);

  const callStop = React.useCallback((emit?: boolean) => {
    callStatusSet('done');
    operatorCurrentSet(null);
    localMediaStream.current !== null
      && localMediaStream.current.getTracks().forEach(track => track.stop());
    localMediaStream.current = null;
    operatorCurrent && delete peerConnections.current[operatorCurrent.clientId];
    operatorCurrentSet(null);
  }, [
    operatorCurrent,
  ]);

  const callerCallStop = React.useCallback(() => {
    socket.emit(ACTIONS.CALLER_CALL_STOP, { operator: operatorCurrent });
    callStop();
  }, [
    operatorCurrent,
    callStop,
  ]);

  const operatorCallStop = React.useCallback(() => {
    if (operatorCurrent === null) {
      console.warn('opertator not defined');
      return;
    }
    
    notifyCall({
      type: 'info',
      message: 'Оператор закончил звонок.',
    });
    
    callStop();
  }, [
    operatorCurrent,
    notifyCall,
    callStop,
  ]);

  const operatorCallRejected = React.useCallback(() => {
    notifyCall({
      type: 'info',
      message: 'Оператор отклонил звонок.',
    });
    
    callStop();
  }, [
    notifyCall,
    callStop,
  ]);

  const goToHome = React.useCallback(() => {
    callStatusSet('idle');
  }, []);

  const clientDisconnect = React.useCallback((event: { clientId: string }) => {
    if (operatorCurrent === null || operatorCurrent.clientId !== event.clientId) {
      return;
    }

    notifyCall({
      type: 'info',
      message: 'Разорвано соединение с оператором.'
    });

    callStop();
  }, [
    operatorCurrent,
    notifyCall,
    callStop,
  ]);

  const resizeWindow = React.useCallback(() => {
    if (callStatus === 'call' || callStatus === 'pending') {
      const height = window.innerHeight;

      const wrapper = document.getElementById('call-wrapper');
      if (wrapper) wrapper.style.height = String(height) + 'px';
    }
  }, [
    callStatus,
  ]);

  React.useEffect(() => {
    setTimeout(resizeWindow, 1000);
    window.addEventListener('resize', resizeWindow);

    return () => {
      window.removeEventListener('resize', resizeWindow);
    }
  }, [
    callStatus,
    resizeWindow,
  ])
  
  return React.useMemo(() => ({
    generalDataSet,
    personalDataSet,
    provideMediaRef,
    callRun,
    offerSet,
    iceCandidateSet,
    operatorCallStop,
    operatorCallRejected,
    callerCallStop,
    clientDisconnect,

    operatorListLen,
    callerListLen,
    personalQueueIdx,
    callStatus,
    callerName,
    operatorCurrent,
    cameraMuted,
    microphoneMuted,
    microphoneList,
    cameraList,
    cameraDeviceId,
    microphoneDeviceId,
    modalSettingsOpened,
    callerStadnToQueue,
    goToHome,
    cameraToggle,
    microphoneToggle,
    cameraSet,
    microphoneSet,
    modalSettingsOpen,
    modalSettingsClose,
  }), [
    generalDataSet,
    personalDataSet,
    provideMediaRef,
    callRun,
    offerSet,
    iceCandidateSet,
    operatorCallStop,
    operatorCallRejected,
    callerCallStop,
    clientDisconnect,

    operatorListLen,
    callerListLen,
    personalQueueIdx,
    callStatus,
    callerName,
    operatorCurrent,
    cameraMuted,
    microphoneMuted,
    microphoneList,
    cameraList,
    cameraDeviceId,
    microphoneDeviceId,
    modalSettingsOpened,
    callerStadnToQueue,
    goToHome,
    cameraToggle,
    microphoneToggle,
    cameraSet,
    microphoneSet,
    modalSettingsOpen,
    modalSettingsClose,
  ]);
};
