import { TransitionOpacityBrick } from '@app/agui/bricks';

import { OperatorVideoComponent } from '../operator-video';
import { CallerVideoComponent } from '../caller-video';
import { ControlsComponent } from '../controls';
import styles from './call.module.scss';


export const CallComponent = () => {
  return (
    <TransitionOpacityBrick>
      <div className={styles['call']} id="call-wrapper" >
        <div className={styles['call__operator-video']}>
          <OperatorVideoComponent />
        </div>
        <div className={styles['call__caller-video']}>
          <CallerVideoComponent />
        </div>
        <div className={styles['call__controls']}>
          <ControlsComponent />
        </div>
      </div>
    </TransitionOpacityBrick>
  );
};
