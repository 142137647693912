import React from 'react';
import cs from 'classnames';
import { useTheme } from '@mui/material';

import { ChangeFieldEventType } from '@app/types';

import {
  ArrowDownIcon,
  FormFieldBrick,
  AsteriskBrick,
  InputBrick,
  MenuItemBrick,
  TypographyBrick,
} from '@app/components';

import { FieldSelectItem, FieldSelectProps } from './field-select.props';
import styles from './field-select.module.scss';


const ITEMS_COUNT = 15;

export const FieldSelectBlock = ({
  value,
  onChange,
  name,
  error,
  label,
  items,
  hideError,
  required,
  counted,
  prompt,
  disabled,
  enableSearch,
  enablePagination,
  dropdownMaxHeight = 400,
  extra,
}: FieldSelectProps) => {
  const [opened, setOpened] = React.useState(false);
  const [filteredItems, setFilteredItems] = React.useState<FieldSelectItem[]>([]);
  const [filterQuery, setFilterQuery] = React.useState('');
  const [loadedCount, setLoadedCount] = React.useState(ITEMS_COUNT);
  const [topValue, setTopValue] = React.useState('100%');
  const [isPositionTop, setIsPositionTop] = React.useState(false);
  const { palette } = useTheme();

  const inputRef = React.useRef(null);

  const selectedValue = React.useMemo(() => {
    const selectedItem = items.find((item) => item.value === value);

    if (selectedItem) return selectedItem.label;

    return '';
  }, [
    value,
    items,
  ]);

  const displayedItems = React.useMemo(() => {
    if (enablePagination) {
      return filteredItems.slice(0, loadedCount)
    }

    return filteredItems;
  }, [
    filteredItems,
    enablePagination,
    loadedCount,
  ]);

  const updatePositionTop = React.useCallback(() => {
    const dropdown = document.getElementById(`field-select__dropdown--${name}`);
    if (!dropdown || !(inputRef as any).current) {
      setIsPositionTop(false);
      return;
    }

    const inputTop: number = (inputRef as any).current.getBoundingClientRect().top;
    const dropdownHeight = dropdown.getBoundingClientRect().height;
    const screenHeight = window.innerHeight;
    const isTop = (inputTop + dropdownHeight + 100) > screenHeight;

    setIsPositionTop(isTop);
  }, [
    name,
  ]);

  const updateTopValue = React.useCallback(() => {
    if (!opened) return;
    if (!isPositionTop) {
      setTopValue('100%');
      return;
    }

    const dropdown = document.getElementById(`field-select__dropdown--${name}`);
    if (!dropdown) return;

    setTopValue('-' + dropdown.getBoundingClientRect().height + 'px');
  }, [
    isPositionTop,
    name,
    opened,
  ]);

  const filterItems = React.useCallback((query: string) => {
    const newList = items.filter((item) => {
      return item.label.toLowerCase().indexOf(query.toLowerCase()) !== -1;
    });

    setFilteredItems(newList);
  }, [
    items,
  ]);

  const filterQueryChangeHandler = React.useCallback((e: ChangeFieldEventType) => {
    setFilterQuery((e.target.value as string));
    filterItems((e.target.value as string));
    setLoadedCount(ITEMS_COUNT);
  }, [
    filterItems,
  ]);

  const loadHandler = React.useCallback(() => {
    setLoadedCount(loadedCount + ITEMS_COUNT);
  }, [
    loadedCount,
  ]);

  const openHandler = React.useCallback(() => {
    if (!disabled) setOpened(true);
  }, [
    disabled,
  ]);

  const closeHandler = React.useCallback(() => {
    setOpened(false);
    setLoadedCount(ITEMS_COUNT);
    setFilterQuery('');
    setFilteredItems(items);
  }, [
    items,
  ]);

  const toggleHandler = React.useCallback((e: any) => {
    e.target.blur();
    if (opened) closeHandler();
    else openHandler();
  }, [
    opened,
    closeHandler,
    openHandler,
  ]);

  const outsideClickHandler = React.useCallback((e: any) => {
    if (inputRef.current && !(inputRef as any).current.contains(e.target)) {
      closeHandler();
    }
  }, [
    closeHandler,
  ]);

  const selectHandler = React.useCallback((value: string | number) => {
    closeHandler();
    onChange({ target: { name, value } });
  }, [
    name,
    closeHandler,
    onChange,
  ]);

  React.useEffect(() => {
    document.addEventListener("mousedown", outsideClickHandler);
    return () => {
      document.removeEventListener("mousedown", outsideClickHandler);
    };
  });

  React.useEffect(() => {
    setFilteredItems(items);
  }, [items]);

  React.useEffect(() => {
    setTimeout(() => {
      if (opened) {
        updateTopValue();
        updatePositionTop();
      }
    }, 0);
  }, [
    opened,
    updateTopValue,
    updatePositionTop,
  ]);

  const classnames = cs(
    styles['field-select'],
    { [styles['field-select--opened']]: opened },
    { [styles['field-select--dark']]: palette.mode === 'dark' },
  );

  return (
    <div className={classnames}
      ref={inputRef}
    >
      <FormFieldBrick
        error={error}
        hideError={hideError}
        prompt={prompt}
        extra={extra}
      >
        <div className={styles['field-select__input']}
          onClick={toggleHandler}
        >
          <InputBrick
            value={selectedValue}
            onChange={() => {}}
            name={name}
            label={<>{label}<AsteriskBrick required={required} counted={counted} /></>}
            disabled={disabled}
          />
          <div className={styles['field-select__dropdown-icon']}>
            <ArrowDownIcon />
          </div>
        </div>
        {opened && (
          <div className={styles['field-select__dropdown']}
            id={`field-select__dropdown--${name}`}
            style={{ top: topValue }}
          >
            {enableSearch && (
              <div className={styles['field-select__search']}>
                <InputBrick
                  value={filterQuery}
                  onChange={filterQueryChangeHandler}
                  name="search"
                  label="Поиск"
                  size="small"
                  autoFocus
                />
              </div>
            )}
            <div
              className={styles['field-select__items']}
              style={{ maxHeight: (dropdownMaxHeight - (enableSearch ? 72 : 0)) + 'px'}}
            >
              {displayedItems.map((item) => (
                <MenuItemBrick
                  onClick={() => selectHandler(item.value)}
                  key={item.value}
                  selected={item.value === value}
                >
                  <TypographyBrick variant="body1" >{item.label}</TypographyBrick>
                </MenuItemBrick>
              ))}
              {(enablePagination && loadedCount < filteredItems.length) && (
                <MenuItemBrick
                  className={styles['field-select__button-load']}
                  onClick={loadHandler}
                >
                  <ArrowDownIcon />
                </MenuItemBrick>
              )}
            </div>
            {filteredItems.length === 0 && (
              <div className={styles['field-select__nothing']}>Ничего нет</div>
            )}
          </div>
        )}
      </FormFieldBrick>
    </div>
  );
};
