import { useMainContext } from '../../context';

import styles from './operator-video.module.scss';


export const OperatorVideoComponent = () => {
  const {
    personalQueueIdx,
    operatorListLen,
    operatorCurrent,
    callStatus,
    provideMediaRef,
  } = useMainContext();

  return (
    <div className={styles['operator-video']}>
      {callStatus === 'pending' && (
        <div className={styles['operator-video__queue-info']}>
          <h6>{`Операторов в сети: ${operatorListLen}`}</h6>
          <h6>{`Человек перед вами: ${personalQueueIdx}`}</h6>
        </div>
      )}
      <video
        id="operator-video"
        width='100%'
        autoPlay
        playsInline
        ref={(node) => {
          provideMediaRef('remote', node);
        }}
      />
      {callStatus === 'call' && (
        <div className={styles['operator-video__operator-name']}>
          <h6 className={styles['operator-video__operator-name-block']}>
            {operatorCurrent ? operatorCurrent.lastName + ' ' + operatorCurrent.firstName + ' ' + (operatorCurrent.secondName || '') : ''}
          </h6>
        </div>
      )}
    </div>
  );
};
