import { LoadingButton } from '@mui/lab';

import { ButtonLoadingProps } from './button-loading.props';


export const ButtonLoadingBrick = ({
  children,
  loading,
  type,
  onClick,
  size = 'medium',
  startIcon,
  variant = 'outlined',
  color = 'primary',
  disabled,
  fullWidth,
}: ButtonLoadingProps) => {
  return (
    <LoadingButton
      loading={loading}
      type={type}
      onClick={onClick}
      size={size}
      startIcon={startIcon}
      variant={variant}
      color={color}
      disabled={disabled}
      fullWidth={fullWidth}
    >
      {children}
    </LoadingButton>
  );
}
