import {
  useMainContext,
  QueueAddProvider,
} from '../../context';

import { CardDataComposition } from '@app/agui/compositions';
import { PageBaseLayout } from '@app/components/layouts';

import { QueueAddDialogComponent } from '../queue-add-dialog';
import { CallComponent } from '../call';


export const MainComponent = () => {
  const {
    callStatus,
    callerListLen,
    operatorListLen,
  } = useMainContext();

  return (
    <>
      {(callStatus === 'idle' || callStatus === 'done') && (

        <PageBaseLayout>
          <CardDataComposition
            title={`Операторов в сети: ${operatorListLen} Человек в очереди: ${callerListLen}`}
          >
            <QueueAddProvider>
              <QueueAddDialogComponent />
            </QueueAddProvider>
          </CardDataComposition>
        </PageBaseLayout>
      )}

      {(callStatus === 'pending' || callStatus === 'call') && (
        <CallComponent />
      )}
    </>
  );
};
