import { useMainContext } from '../../context';

export const CallerVideoComponent = () => {
  const {
    cameraMuted,
    provideMediaRef,
  } = useMainContext();

  return (
    <video
      id="caller-video"
      width='100%'
      autoPlay
      playsInline
      muted={true}
      style={{ display: 'block', marginBottom: '0' }}
      ref={(node) => {
        provideMediaRef('local', node);
      }}
      hidden={cameraMuted}
    />
  );
};
